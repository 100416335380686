<template>
    <div class="container">
      <div class="main">
        <el-table :data="tableData" style="width: 100%" border :span-method="arraySpanMethod" >
          <el-table-column prop="oneIndexName" label="评价维度" width="90"></el-table-column>
          <el-table-column prop="twoIndexName" label="评价要素" width="100"></el-table-column>
          <el-table-column prop="content" label="核心内容" min-width="230"></el-table-column>
          <el-table-column prop="labelName" label="评价标签" min-width="120"></el-table-column>
          <el-table-column prop="indexMain" label="记录要点" min-width="150"></el-table-column>
          <el-table-column prop="indexShowType" label="评价方式" width="100"></el-table-column>
          <el-table-column prop="rulesDes" label="评分规则" width="170"></el-table-column>
          <el-table-column prop="roundName" label="评价周期" width="90"></el-table-column>
        </el-table>
      </div>
    </div>
</template>

<script>
import {getIndexSystem} from '@/api/teacher/quality/indexSystem'
export default {
  data() {
    return {
        tableData: [],
    };
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
        getIndexSystem({}).then(res => {
            // console.log(res);
            this.tableData = res.data.indexList
            this.merage(this.tableData)
        }) 
    },
    merageInit() {
      this.indexOneArr = [];
      this.indexOnePos = 0;
      this.indexTwoArr = [];
      this.indexTwoPos = 0;
      this.contentArr = [];
      this.contentPos = 0;
    },
    merage(arr) {
      this.merageInit();
      arr.forEach((item, index) => {
        if (index == 0) {
          this.indexOneArr.push(1);
          this.indexOnePos = 0;
          this.indexTwoArr.push(1);
          this.indexTwoPos = 0;
          this.contentArr.push(1);
          this.contentPos = 0;
        } else {
          if (arr[index].oneIndexName == arr[index - 1].oneIndexName) {
            this.indexOneArr[this.indexOnePos] += 1;
            this.indexOneArr.push(0);
          } else {
            this.indexOneArr.push(1), (this.indexOnePos = index);
          }
          if (arr[index].twoIndexName == arr[index - 1].twoIndexName) {
            this.indexTwoArr[this.indexTwoPos] += 1;
            this.indexTwoArr.push(0);
          } else {
            this.indexTwoArr.push(1), (this.indexTwoPos = index);
          }
          if (arr[index].content == arr[index - 1].content) {
            this.contentArr[this.contentPos] += 1;
            this.contentArr.push(0);
          } else {
            this.contentArr.push(1), (this.contentPos = index);
          }
        }
      });
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        const _row = this.indexOneArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex == 1) {
        const _row1 = this.indexTwoArr[rowIndex];
        const _col1 = _row1 > 0 ? 1 : 0;
        return {
          rowspan: _row1,
          colspan: _col1,
        };
      }
      if (columnIndex == 2) {
        const _row2 = this.contentArr[rowIndex];
        const _col2 = _row2 > 0 ? 1 : 0;
        return {
          rowspan: _row2,
          colspan: _col2,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
    .main {
        margin: 0;
    }
}
</style>
<style scoped>
.el-table >>> .el-table__row:hover > td {
  background-color: #fff !important;
}
.el-table >>> .el-table__row--striped:hover > td {
  background-color: #fff !important;
}
</style>